import React, { FunctionComponent, useCallback, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import Section from 'ui/atoms/section';
import ProductsTable from 'apps/issuer/pages/products/products-table';
import useGoTo from 'hooks/use-go-to';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { useProducts, useProductsFilter } from 'apps/issuer/hooks';
import Link from 'core/routing/link';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { FilterInputOption, FilterPopup, FilterRange } from 'ui/molecules/filtering';
import FilterMultiSelect from 'ui/molecules/filtering/filter-popup/filter-multi-select';
import { useCurrentUserSelector } from 'src/core/auth/hooks';
import Button from 'src/ui/atoms/button';
import AddProductModal from './add-product-modal';
import FilterMultiGroupOption from 'src/ui/molecules/filtering/filter-popup/filter-multi-group-option';

const IssuerInvestorDetailsPageFilters: FunctionComponent = () => {
  const translate = useTranslateWithStringArgs();

  const filterLabels = {
    totalVolume: 'totalVolume',
    totalVolumeMin: 'totalVolumeMin',
    totalVolumeMax: 'totalVolumeMax',
    status: 'status',
  };

  const productStatusFilterOptions = [
    {
      value: ['processing'],
      label: translate('issuerProducts.status.processing'),
      name: 'processing',
    },
    {
      value: ['waiting'],
      label: translate('issuerProducts.status.waiting'),
      name: 'waiting',
    },
    {
      value: ['succeeded'],
      label: translate('issuerProducts.status.succeeded'),
      name: 'succeeded',
    },
    {
      value: ['failed'],
      label: translate('issuerProducts.status.failed'),
      name: 'failed',
    },
    {
      value: ['unknown'],
      label: translate('issuerProducts.status.unknown'),
      name: 'unknown',
    },
    {
      value: ['queued'],
      label: translate('issuerProducts.status.queued'),
      name: 'queued',
    },
  ];

  const { tokens } = useProducts();
  const { productsFilter } = useProductsFilter(tokens);

  return (
    <>
      <FilterPopup>
        <FilterMultiSelect
          label={translate('studioInvestmentsTable.product')}
          placeholder={translate('filters.multiselect.placeholderProduct')}
          options={productsFilter}
          input="id"
          defaultField="id"
        />
        <FilterRange
          label={translate('filters.labels.totalVolume')}
          inputNames={[filterLabels.totalVolumeMin, filterLabels.totalVolumeMax]}
          filterName={filterLabels.totalVolume}
        >
          <FilterInputOption
            input={filterLabels.totalVolumeMin}
            pairedInput={filterLabels.totalVolumeMax}
            type="number"
            label={null}
            defaultField={filterLabels.totalVolumeMin}
            name={filterLabels.totalVolume}
          />
          <FilterInputOption
            input={filterLabels.totalVolumeMax}
            pairedInput={filterLabels.totalVolumeMin}
            type="number"
            label={null}
            defaultField={filterLabels.totalVolumeMax}
            name={filterLabels.totalVolume}
          />
        </FilterRange>
        <FilterMultiGroupOption
          input={filterLabels.status}
          defaultField={filterLabels.status}
          label={translate('studioInvestmentsTable.status')}
          options={productStatusFilterOptions}
        />
      </FilterPopup>
    </>
  );
};

const IssuerProductsPage = () => {
  const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
  const [forceDataLoad, setForceDataLoad] = useState(false);

  const { loading, tokens } = useProducts();
  const { currentUser } = useCurrentUserSelector();

  const goToProductDetails = useGoTo(ISSUER_ROUTES.productDetails);

  const refreshTableData = useCallback(() => {
    setForceDataLoad(true);
    setAddProductModalOpen(false);
    setForceDataLoad(false);
  }, []);

  // this should never happen though
  if (!tokens) {
    return null;
  }

  return (
    <WideContent title={<Translate name="dashboard.issuerProducts" />}>
      <Section spacing="small">
        <Translate name="issuerProducts.subtitle" />
      </Section>
      {currentUser?.canCreateProducts && (
        <Section spacing="medium">
          <Button
            variant="secondary"
            icon="plus"
            size="xsmall"
            iconSize="small"
            onClick={() => setAddProductModalOpen(true)}
          >
            <Translate name="addProduct.add" />
          </Button>
        </Section>
      )}
      <Section spacing="medium">
        <ProductsTable
          defaultOrdering={{ direction: 'asc', fieldName: 'last_name' }}
          defaultLimit={50}
          goToDetails={(productId: string) => goToProductDetails({ productId })}
          loading={loading}
          forceDataLoad={forceDataLoad}
          filters={<IssuerInvestorDetailsPageFilters />}
        />
      </Section>
      <Link icon="arrow-left" to="/dashboard/overview">
        <Translate as="span" name="studioConfirmMultiples.action.backToOverview" />
      </Link>
      {isAddProductModalOpen && (
        <AddProductModal onClose={() => setAddProductModalOpen(false)} onAddNewProduct={refreshTableData} />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerProductsPage, AppType.ISSUER);
