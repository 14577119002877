import { AdminIssuerDetailPermissionsEnum, UserPermissionsEnum } from 'api';

export const currentUserHasPermission = (permissions: UserPermissionsEnum[], permission: UserPermissionsEnum) =>
  !!permissions.find((perm) => perm === permission);

export const currentIssuerHasPermission = (
  permissions: AdminIssuerDetailPermissionsEnum[],
  permission: AdminIssuerDetailPermissionsEnum,
) => !!permissions.find((perm) => perm === permission);

export const currentUserHasCreateIssuerPermission = (permissions: UserPermissionsEnum[]) =>
  currentUserHasPermission(permissions, UserPermissionsEnum.CREATE_ISSUER);

export const currentIssuerHasEditIssuerPermission = (permissions: AdminIssuerDetailPermissionsEnum[]) =>
  currentIssuerHasPermission(permissions, AdminIssuerDetailPermissionsEnum.EDIT_ISSUER_CASHLINK_STUDIO);
