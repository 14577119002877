import { resolveColor } from 'ui/styles/utils/colors';
import Size from 'ui/types/size';
import Spacing from 'ui/types/spacing';
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components';
import { HorizontalAlign } from 'src/ui/types/align';
import { query } from 'ui/styles/queries';

export interface InputProps {
  $isCurrency?: boolean;
  $isPercentage?: boolean;
  $isDate?: boolean;
  $hasLabel?: boolean;
  $textAlign?: HorizontalAlign;
  $alignIcon?: string;
  $isStepper?: boolean;
  type?: string;
}

export interface InputContainerProps {
  $spacing?: Spacing;
  $width?: Size;
  $loading?: boolean;
  $hover?: boolean;
  $focused?: boolean;
  $isInvalid?: boolean;
  $height?: Size;
  disabled?: boolean;
}

export interface LabelProps {
  $type?: string;
  $isInvalid?: boolean;
  $isCurrency?: boolean;
  $isDate?: boolean;
  $focused: boolean;
  $alignIcon?: string;
  $inputHasValue?: boolean;
}

export interface SymbolProps {
  $focused: boolean;
  $alignIcon?: string;
}

function getInputContainerDefaultStyle({ theme, $height }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  return css`
    display: flex;
    flex-grow: 100;
    position: relative;
    outline: 1px solid ${theme.input.borderColor};
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
    height: ${$height === 'large' ? '3.75rem' : '2.25rem'};

    ${query.phone`
      font-size: 0.875rem;
    `};
  `;
}

function getInputContainerSpacing({ theme, $spacing }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  if (!$spacing) return null;

  const spacing = theme.spacing[$spacing];
  return css<InputContainerProps>`
    &:not(:last-child) {
      margin-bottom: ${spacing}rem;
    }
  `;
}

function getInputContainerWidth({ theme, $width }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  if (!$width) return null;

  const width = 2.6 * theme.sizes[$width];
  return css<InputContainerProps>`
    &:not(:last-child) {
      width: ${width}rem;
    }
  `;
}

function getInputDefaultStyle({ theme, $hasLabel, $textAlign }: ThemedStyledProps<InputProps, DefaultTheme>) {
  return css<InputProps>`
    ${
      $hasLabel &&
      ` 
        position: absolute;
        bottom: 10.5px;
      `
    }
    background-image: none;
    background-clip: padding-box;
    background-color: ${resolveColor(theme, 'white')};
    border: none;
    outline: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    text-overflow: ellipsis;
    text-align: ${$textAlign};
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-background-clip: text;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
    &::placeholder {
      color: ${theme.input.labelColor};
    }
  }`;
}

function getPadding({
  $isCurrency,
  $isPercentage,
  $hasLabel,
  $alignIcon,
  $isStepper,
}: ThemedStyledProps<InputProps, DefaultTheme>) {
  return css<InputProps>`
    padding: 0rem 0rem;
    padding-top: ${$hasLabel ? '0.75rem' : '0'};
    padding-right: ${
      $isCurrency || $isPercentage ? '2rem' : $alignIcon === 'right' ? '2.2rem' : $isStepper ? '2.6rem' : '1rem'
    };
    padding-left: ${$alignIcon === 'left' ? '2.5rem' : '1rem'};
  }`;
}

function getDisabled({ theme, disabled }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  if (!disabled) return null;

  return css<InputContainerProps>`
    background-color: ${theme.input.disabledColor};
    opacity: 1;
  }`;
}

function getInputFocus({ theme, $focused, disabled }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  if (!$focused || disabled) return null;

  return css<InputContainerProps>`
    background-color: #fff;
    outline: 2px solid ${theme.input.borderFocusedColor};
    border: none;
  }`;
}

function getInputHover({ theme, disabled, $hover }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  if (disabled || !$hover) return null;

  return css<InputContainerProps>`
    background-color: #fff;
    outline: 2px solid ${theme.input.borderFocusedColor};
    border: none;
  }`;
}

function getValidation({ theme, $isInvalid, $focused }: ThemedStyledProps<InputContainerProps, DefaultTheme>) {
  if (!$isInvalid) return null;

  const color = $isInvalid && !$focused ? 'danger' : 'initial';

  return css<InputContainerProps>`
    background-position: right calc(0.375em + 0.1875rem) center;
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    outline: 2px solid ${resolveColor(theme, color)};
    border: none;
  }`;
}

function getLabelFocus({ theme, $focused }: ThemedStyledProps<LabelProps, DefaultTheme>) {
  if (!$focused) return null;

  return css<LabelProps>`
    top: 14.5px;
    left: 1rem;
    right: auto;
    transform: translate(0%, 0%);
    opacity: 1;
    color: ${theme.input.labelFocusedColor};
    font-size: 0.75rem;
    line-height: 0.6rem;

    ${query.phone`
      font-size: 11px;
    `};
  }`;
}

function getLabelInputFilled({ $inputHasValue }: ThemedStyledProps<LabelProps, DefaultTheme>) {
  if (!$inputHasValue) return null;

  return css<LabelProps>`
    top: 14.5px;
    left: 1rem;
    right: auto;
    transform: translate(0%, 0%);
    font-size: 0.75rem;
    line-height: 0.6rem;
    opacity: 1;

    ${query.phone`
      font-size: 11px;
    `};
  }`;
}

function getLabelInputAutofilled() {
  return css<LabelProps>`
    ${Input}:-webkit-autofill + & {
      top: 14.5px;
      left: 1rem;
      right: auto;
      transform: translate(0%, 0%);
      font-size: 0.75rem;
      line-height: 0.6rem;
      opacity: 1;

      ${query.phone`
        font-size: 11px;
      `};
    }
  `;
}

function getLabelDefaultStyle({ theme, $isInvalid }: ThemedStyledProps<LabelProps, DefaultTheme>) {
  return css<LabelProps>`
    position: absolute;
    pointer-events: none;
    left: 1rem;
    right: auto;
    top: 50%;
    transform: translate(0%, -50%);
    transition: 0.2s ease all;
    color: ${$isInvalid ? resolveColor(theme, 'danger') : theme.input.labelColor};
    line-height: 0.9rem;
    font-size: 1rem;

    ${query.phone`
      font-size: 0.875rem;
    `};
  }`;
}

function getSymbolDefaultStyle({ theme, $focused }: ThemedStyledProps<SymbolProps, DefaultTheme>) {
  return css<SymbolProps>`
    position: absolute;
    transform: translate(0%, -50%);
    top: 50%;
    right: 1rem;
    color: ${theme.input.labelColor};
    padding-top: ${$focused ? '0.75rem' : '0'}
  }`;
}

export const InputContainer = styled.div<InputContainerProps>`
  ${getInputContainerDefaultStyle};
  ${getInputContainerSpacing};
  ${getInputContainerWidth};
  ${getInputHover};
  ${getDisabled};
  ${getInputFocus};
  ${getValidation};
`;

export const Label = styled.label<LabelProps>`
  ${getLabelDefaultStyle};
  ${getLabelFocus};
  ${getLabelInputFilled};
  ${getLabelInputAutofilled};
`;

export const Symbol = styled.span<SymbolProps>`
  ${getSymbolDefaultStyle};
`;

export const Input = styled.input<InputProps>`
  ${getInputDefaultStyle};
  ${getPadding};
`;
