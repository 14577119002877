import React, { FunctionComponent, useState, useCallback, ReactNode, useMemo, useEffect } from 'react';
import Translate from 'ui/atoms/translate';
import WithAuthenticatedPage from 'core/auth/components/with-authenticated-page';
import { AppType } from 'core/auth/types';
import WithDataRecord from 'hoc/WithDataRecord';
import { AdminApi } from 'api/apis';
import { AdminIssuerList } from 'api/models';
import StudioIssuersTable from './studio-issuers-table';
import useGoTo from 'hooks/use-go-to';
import IssuerEmptyView from 'core/components/empty-views/issuer';
import { EmptyViewType } from 'src/libraries/dashboard-empty-view';
import WideContent from 'core/layout/content/wide-content';
import { FilterInputOption, FilterPopup, FilterRange, FiltersWrapper } from 'ui/molecules/filtering';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import { DataRecordOrdering } from 'ui/types/data-ordering';
import { filterPossiblyNullStringValues } from 'ui/molecules/filtering/helpers';
import Section from 'ui/atoms/section';
import Button from 'ui/atoms/button';
import AddIssuerModal from 'apps/issuer/pages/issuers/add-issuer-modal';
import { useTranslateWithStringArgs } from 'ui/hooks/use-translate';
import { useCurrentUserSelector } from 'core/auth/hooks';
import FilterMultiSelect, { MultiSelectItem } from 'ui/molecules/filtering/filter-popup/filter-multi-select';
import { debounce } from 'lodash';
import useApiCall from 'src/hooks/use-api-call';
import { currentUserHasCreateIssuerPermission } from '../../helpers/issuerPermissions';

interface IssuersTableProps {
  defaultOrdering: DataRecordOrdering;
  defaultLimit: number;
  forceDataLoad?: boolean;
  filters?: ReactNode;
}

const IssuerIssuersPageFilters: FunctionComponent<{}> = () => {
  const { withApi, makeAuthenticatedApi } = useApiCall();
  const adminApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);
  const translate = useTranslateWithStringArgs();
  const [issuersSearched, setIssuersSearched] = useState<MultiSelectItem[]>([]);

  const filterLabels = {
    numberOfProducts: 'numberOfProducts',
    numberOfProductsMin: 'numberOfProductsMin',
    numberOfProductsMax: 'numberOfProductsMax',
  };

  const searchIssuerName = useCallback((value: string) => {
    withApi(async () => {
      const issuers = await adminApi.adminIssuersList({
        search: value,
        limit: 10,
      });
      setIssuersSearched(
        issuers.results?.length
          ? issuers.results.map((issuer) => ({
              value: issuer.id,
              label: issuer.companyName,
              name: issuer.companyName,
            }))
          : [],
      );
    });
  }, []);

  useEffect(() => {
    searchIssuerName('');
  }, [searchIssuerName]);

  const debouncedSearch = debounce(searchIssuerName, 500);

  return (
    <>
      <FilterPopup>
        <FilterMultiSelect
          label={translate('filters.labels.issuerName')}
          placeholder={translate('filters.multiselect.placeholderName')}
          options={issuersSearched}
          input="issuerId"
          defaultField="issuerId"
          onSearch={debouncedSearch}
        />
        <FilterRange
          label={translate('filters.labels.productsNumber')}
          inputNames={[filterLabels.numberOfProductsMin, filterLabels.numberOfProductsMax]}
          filterName={filterLabels.numberOfProducts}
        >
          <FilterInputOption
            input={filterLabels.numberOfProductsMin}
            pairedInput={filterLabels.numberOfProductsMax}
            type="number"
            label={null}
            defaultField={filterLabels.numberOfProductsMin}
            name={filterLabels.numberOfProducts}
          />
          <FilterInputOption
            input={filterLabels.numberOfProductsMax}
            pairedInput={filterLabels.numberOfProductsMin}
            type="number"
            label={null}
            defaultField={filterLabels.numberOfProductsMax}
            name={filterLabels.numberOfProducts}
          />
        </FilterRange>
      </FilterPopup>
    </>
  );
};

const IssuerIssuersDataRecordTable: FunctionComponent<IssuersTableProps> = WithDataRecord<AdminApi, AdminIssuerList>(
  AdminApi,
)(
  ({ data, ordering, onOrderBy, loading, filters, paginationProps }) => {
    const goTo = useGoTo(ISSUER_ROUTES.issuerDetails);

    return (
      <>
        {filters && <>{filters}</>}
        <StudioIssuersTable
          onOrderBy={onOrderBy}
          ordering={ordering}
          issuers={data}
          loading={loading}
          emptyView={<IssuerEmptyView type={EmptyViewType.ISSUER_ISSUERS} />}
          goToDetails={(issuerId: string) => goTo({ issuerId })}
          paginationProps={paginationProps}
        />
      </>
    );
  },
  (api, props, offset, limit) => {
    const { formattedFilter, restOfFilters } = filterPossiblyNullStringValues(props, 'tokenId');

    return api.adminIssuersList({
      offset,
      limit,
      ...formattedFilter,
      ...restOfFilters,
    });
  },
);

const IssuerIssuersPage: FunctionComponent<{}> = () => {
  const [isAddIssuerModalOpen, setAddIssuerModalOpen] = useState(false);
  const [forceDataLoad, setForceDataLoad] = useState(false);

  const { currentUser } = useCurrentUserSelector();

  const refreshTableData = useCallback(() => {
    setForceDataLoad(true);
    setAddIssuerModalOpen(false);
    setForceDataLoad(false);
  }, []);

  if (!currentUser) return null;

  return (
    <WideContent title={<Translate name="dashboardIssuerIssuers.title" />}>
      <Section spacing="medium">
        <Translate name="studioIssuers.subtitle" />
      </Section>
      {currentUserHasCreateIssuerPermission(currentUser.permissions) && (
        <Button
          variant="secondary"
          icon="plus"
          size="xsmall"
          iconSize="small"
          onClick={() => setAddIssuerModalOpen(true)}
        >
          <Translate name="studioIssuers.addIssuerButton" />
        </Button>
      )}
      <IssuerIssuersDataRecordTable
        defaultOrdering={{ direction: 'asc', fieldName: 'last_name' }}
        defaultLimit={50}
        forceDataLoad={forceDataLoad}
        filters={
          <FiltersWrapper>
            <IssuerIssuersPageFilters />
          </FiltersWrapper>
        }
      />
      {isAddIssuerModalOpen && (
        <AddIssuerModal onClose={() => setAddIssuerModalOpen(false)} onAddNewIssuer={refreshTableData} />
      )}
    </WideContent>
  );
};

export default WithAuthenticatedPage(IssuerIssuersPage, AppType.ISSUER);
