import React, { FunctionComponent, useState } from 'react';
import WideContent from 'core/layout/content/wide-content';
import { AdminProductDetail } from 'api';
import Translate from 'ui/atoms/translate';
import IssuerCreateCampaign from './create-campaign';
import ProductCampaignsTable from './campaigns-table';
import { ISSUER_ROUTES } from 'apps/issuer/pages/routes.config';
import useGoTo from 'hooks/use-go-to';
import { currentTokenHasManageDistributionPermission } from 'src/apps/issuer/helpers/tokenPermissions';

interface ProductCampaignProps {
  product: AdminProductDetail;
  productId: string;
}

const ProductCampaigns: FunctionComponent<ProductCampaignProps> = ({ product, productId }) => {
  const [refreshData, setRefreshData] = useState(false);

  const goToCampaignDetails = useGoTo(ISSUER_ROUTES.campaignDetails);

  const canManage = currentTokenHasManageDistributionPermission(product);

  return (
    <WideContent
      title={<Translate name="dashboard.issuerCampaigns" />}
      ignorePageTitle
      titleSpacing="small"
      headerPanel={
        canManage ? (
          <IssuerCreateCampaign onCloseAfterSuccess={() => setRefreshData(!refreshData)} product={product}>
            <Translate name="campaignInvitation.create.long" />
          </IssuerCreateCampaign>
        ) : null
      }
    >
      <ProductCampaignsTable
        productId={productId}
        goToDetails={(campaignId: string) => goToCampaignDetails({ campaignId })}
        defaultOrdering={{ direction: 'desc', fieldName: 'created_at' }}
        defaultLimit={10}
      />
    </WideContent>
  );
};

export default ProductCampaigns;
