import { useState } from 'react';

// todo: this belongs in redux, but investment app migration to redux is a tad bit tricky
const useEditIssuerData = () => {
  const [isEditCompanyDataOpen, setEditCompanyDataOpen] = useState(false);

  const [isEditCeoOpen, setEditCeoOpen] = useState(false);

  const [isEditBankAccountOpen, setEditBankAccountOpen] = useState(false);

  const [isSuccessEditOpen, setSuccessEditOpen] = useState(false);

  const setEditCompanyDataModal = (value: boolean) => {
    setEditCompanyDataOpen(value);
  };

  const setEditBankAccountModal = (value: boolean) => {
    setEditBankAccountOpen(value);
  };

  const setEditCeoModal = (value: boolean) => {
    setEditCeoOpen(value);
  };

  const setSuccessModal = (value: boolean) => {
    setSuccessEditOpen(value);
  };

  return {
    isEditCompanyDataOpen,
    isEditCeoOpen,
    isEditBankAccountOpen,
    isSuccessEditOpen,
    setEditCompanyDataModal,
    setEditCeoModal,
    setEditBankAccountModal,
    setSuccessModal,
  };
};

export default useEditIssuerData;
