import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import CreateCampaignModal, { CreateCampaignFields } from './create-campaign-modal';
import Button from 'ui/atoms/button';
import useApiCall from 'hooks/use-api-call';
import { AdminApi } from 'api/apis';
import { toMoney, toNumber } from 'ui/helper/money';
import { handleError } from 'ui/helper/error-handling';
import useClearAfterTimeout from 'ui/hooks/use-clear-after-timeout';
import useTranslate from 'ui/hooks/use-translate';
import { AdminProductDetail, Campaign, IssuerInvestmentRules, Money } from 'api/models';
import Tooltip from 'ui/atoms/tooltip';
import Translate from 'ui/atoms/translate';

interface IssuerCreateCampaignProps {
  onCloseAfterSuccess?: () => void;
  product: AdminProductDetail;
}

interface CampaignCalculations {
  remainingTokens: number | undefined;
  investmentTotalMin: Money | undefined;
  investmentTotalMax: Money | undefined;
}

const IssuerCreateCampaign: FunctionComponent<IssuerCreateCampaignProps> = (props) => {
  const { onCloseAfterSuccess = () => {}, children, product } = props;

  const [isCreateCampaignOpen, setIsCreateCampaignOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingCalculation, setIsLoadingCalculation] = useState(false);

  const { withApi, makeAuthenticatedApi, error } = useApiCall(true);

  const { withApi: withCalcApi, makeAuthenticatedApi: makeAuthenticatedCalcApi, error: calcError } = useApiCall(false);

  const {
    withApi: withCampaignsApi,
    loading: loadingCampaignsApi,
    makeAuthenticatedApi: makeAuthenticatedCampaignsApi,
    error: createCampaignError,
  } = useApiCall(false);

  const [values, setValues] = useState<CreateCampaignFields>();

  const [campaign, setCampaign] = useState<Campaign>();

  const [calculations, setCalculations] = useState<CampaignCalculations>({
    remainingTokens: undefined,
    investmentTotalMin: undefined,
    investmentTotalMax: undefined,
  });

  const [ruleSets, setRuleSets] = useState<IssuerInvestmentRules[]>();

  const tokensCalcApi: AdminApi = useMemo(() => makeAuthenticatedCalcApi(AdminApi), [makeAuthenticatedCalcApi]);

  const invitationRulesApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const campaignsApi: AdminApi = useMemo(
    () => makeAuthenticatedCampaignsApi(AdminApi),
    [makeAuthenticatedCampaignsApi],
  );

  const { getRemainingError } = handleError({
    error: useClearAfterTimeout(error),
    translate: useTranslate(),
  });

  const answerErrors = getRemainingError();

  const getCalculations = useCallback(() => {
    setIsLoadingCalculation(true);

    withCalcApi(async (utilities) => {
      if (!product.id) return;

      let remainingTokensCalc: number | undefined = undefined;
      let investmentTotalMinCalc: Money | undefined = undefined;
      let investmentTotalMaxCalc: Money | undefined = undefined;

      const { takeLatest } = utilities;
      try {
        const { remainingTokens } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: product.id,
          requestedTokens: 0,
        });
        remainingTokensCalc = remainingTokens;
      } catch (error) {
        remainingTokensCalc = undefined;
      }
      try {
        const { investmentTotal: investmentTotalMin } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: product.id,
          ...(values &&
            values.minNumberOfTokens && {
              requestedTokens: toNumber(values.minNumberOfTokens),
            }),
          ...(values &&
            values.pricePerToken && {
              pricePerToken: toNumber(values.pricePerToken),
            }),
        });
        investmentTotalMinCalc = investmentTotalMin;
      } catch (error) {
        investmentTotalMinCalc = undefined;
      }
      try {
        const { investmentTotal: investmentTotalMax } = await tokensCalcApi.adminTokensCalculationsRetrieve({
          id: product.id,
          ...(values &&
            values.maxNumberOfTokens && {
              requestedTokens: toNumber(values.maxNumberOfTokens),
            }),
          ...(values &&
            values.pricePerToken && {
              pricePerToken: toNumber(values.pricePerToken),
            }),
        });
        investmentTotalMaxCalc = investmentTotalMax;
      } catch (error) {
        investmentTotalMaxCalc = undefined;
      }
      takeLatest(() => {
        setCalculations({
          remainingTokens: remainingTokensCalc,
          investmentTotalMin: investmentTotalMinCalc,
          investmentTotalMax: investmentTotalMaxCalc,
        });
        setIsLoadingCalculation(false);
      });
    });
  }, [withCalcApi, tokensCalcApi, product.id, values]);

  useEffect(() => {
    getCalculations();
  }, [values, product.id, getCalculations]);

  const createCampaign = useCallback(
    (values: CreateCampaignFields) => {
      withCampaignsApi(async () => {
        if (!product.id) return;
        const campaign = await campaignsApi.adminTokensCampaignsCreate({
          id: product.id,
          campaignRequest: {
            distributionPlatform: values.distributionPlatform,

            allocation: toNumber(values.allocation),

            // TODO: add currency after corresponding backend changes in campaignsCreate api
            // currency: values.currency

            // TODO: default currency "EUR" correct?
            pricePerToken: toMoney(values.pricePerToken.replace(',', '.')),

            minNumberOfTokens: toNumber(values.minNumberOfTokens),
            maxNumberOfTokens: toNumber(values.maxNumberOfTokens),

            // TODO: use Type Country/CampaignCountryEnum instead of any
            country: values.country as any,

            // TODO: use Type CampaignRuleType/CampaignRuleTypeEnum instead of any
            ruleType: values.ruleType as any,

            name: values.name,

            legalPersonsAllowed: values.legalPersonsAllowed ? values.legalPersonsAllowed === 'true' : undefined,

            naturalPersonsAllowed: values.naturalPersonsAllowed ? values.naturalPersonsAllowed === 'true' : undefined,

            active: true,
          },
        });
        setCampaign(campaign);
      });
    },
    [withCampaignsApi, campaignsApi, product.id],
  );

  const loadData = useCallback(() => {
    withApi(async () => {
      if (!product.id) return;
      const rules = await invitationRulesApi.adminTokensInvitationRulesList({
        id: product.id,
      });
      setRuleSets(rules);
    });
  }, [withApi, product.id, invitationRulesApi, setRuleSets]);

  const openCampaignCreation = async () => {
    setCampaign(undefined);
    setValues(undefined);
    setIsCreateCampaignOpen(true);
    loadData();
  };

  useEffect(() => {
    if (!product.id) return;
    getCalculations();
  }, [product.id, values, getCalculations]);

  useEffect(() => {
    if (answerErrors) setIsLoading(false);
  }, [answerErrors]);

  useEffect(() => {
    setIsLoading(isCreateCampaignOpen && (!product.id || !calculations));
  }, [product.id, calculations, isCreateCampaignOpen]);

  return (
    <>
      {isCreateCampaignOpen && product.id && (
        <CreateCampaignModal
          loading={loadingCampaignsApi || isLoadingCalculation}
          open={true}
          onSubmit={createCampaign}
          onCloseAfterSuccess={() => {
            onCloseAfterSuccess();
            setIsCreateCampaignOpen(false);
          }}
          onCancelTriggered={() => setIsCreateCampaignOpen(false)}
          onChange={setValues}
          currencies={product.currencies}
          allocationUpperLimit={calculations.remainingTokens}
          backToBackMinPrice={product.backToBackMinPrice}
          investmentTotalMin={calculations && calculations.investmentTotalMin}
          investmentTotalMax={calculations && calculations.investmentTotalMax}
          distributionPlatforms={product.distributionPlatforms}
          ruleSets={ruleSets}
          calculationError={calcError}
          createCampaignError={createCampaignError}
          campaignLink={campaign?.campaignLink}
        />
      )}
      {!product ||
        !product.id ||
        (product.remainingAmount > 0 && (
          <Button
            variant="primary"
            loading={isLoading}
            disabled={!product || !product.id}
            onClick={openCampaignCreation}
            error={answerErrors}
          >
            {children}
          </Button>
        ))}
      {product && product.remainingAmount <= 0 && (
        <Tooltip maxWidth={188} content={<Translate name="campaignInvitation.noUnitsAllocation" />}>
          <Button variant="primary" disabled={true}>
            {children}
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default IssuerCreateCampaign;
