import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { Money } from 'api';
import Translate from 'ui/atoms/translate';
import ActionButtons from 'ui/molecules/action-buttons';
import Button from 'ui/atoms/button';
import { AdminApi, AdminInvestmentVoucher, InvestmentCancellationEnum, InvestmentCompliance, Product } from 'api';
import useApiCall from 'hooks/use-api-call';
import InvestmentCancellationModal, {
  CancellationModalType,
} from 'src/apps/issuer/shared/investment-cancellation-modal';
import PaymentTotalListing from 'src/libraries/payment-total-listing';

export interface StudioInvestmentPaymentDetailsProps {
  agio: Money | null;
  disagio: Money | null;
  investmentTotalFulfilled: Money | null;
  accruedInterest: Money;
  canCancelReasons: InvestmentCancellationEnum[];
  token: Product;
  investmentId: string;
  paymentTotal: Money;
  tokenPrice: Money;
  loadData: () => void;
  cancellationDate: Date | null;
  hasManageDataPermission: boolean;
  acceptedDate: Date | null;
  investmentCompliance: InvestmentCompliance | null;
  voucher: AdminInvestmentVoucher | null;
  useUnits: boolean;
}

const StudioInvestmentPaymentDetails: FunctionComponent<StudioInvestmentPaymentDetailsProps> = (props) => {
  const {
    investmentTotalFulfilled,
    paymentTotal,
    tokenPrice,
    agio,
    disagio,
    accruedInterest,
    canCancelReasons,
    investmentId,
    loadData,
    token,
    cancellationDate,
    hasManageDataPermission,
    acceptedDate,
    investmentCompliance,
    voucher,
    useUnits,
  } = props;

  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);
  const [errorCancellation, setErrorCancellation] = useState(false);
  const [successCancellation, setSuccessCancellation] = useState(false);

  const { withApi, makeAuthenticatedApi, loading } = useApiCall();

  const investmentsApi: AdminApi = useMemo(() => makeAuthenticatedApi(AdminApi), [makeAuthenticatedApi]);

  const onCancelInvestment = useCallback(() => {
    if (!canCancelReasons?.length) return;
    const cancelReason = InvestmentCancellationEnum.PAYMENT_MISSING;

    withApi(async () => {
      try {
        await investmentsApi.adminInvestmentsCancellationCreate({
          id: investmentId,
          investmentCancellationRequest: {
            reason: cancelReason,
          },
        });
        setSuccessCancellation(true);
        loadData();
      } catch (e) {
        setErrorCancellation(true);
        console.error(e);
      }
    });
  }, [investmentId, JSON.stringify(canCancelReasons)]);

  const canCancelInvestment: boolean = useMemo(
    () =>
      !!canCancelReasons.length &&
      canCancelReasons.includes(InvestmentCancellationEnum.PAYMENT_MISSING) &&
      hasManageDataPermission,
    [JSON.stringify(canCancelReasons), hasManageDataPermission],
  );

  useEffect(() => {
    if (cancellationModalOpen) {
      setSuccessCancellation(false);
      setErrorCancellation(false);
    }
  }, [cancellationModalOpen]);

  return (
    <>
      <div className={'payment-total-listing'}>
        <PaymentTotalListing
          investmentTotal={investmentTotalFulfilled}
          investmentId={investmentId}
          isInvestmentAccepted={!!acceptedDate}
          hasCompliance={!!investmentCompliance}
          hasManageDataPermission={hasManageDataPermission}
          disagio={disagio}
          agio={agio}
          paymentTotal={paymentTotal}
          accruedInterest={accruedInterest}
          showAccruedInterest={token.calculateAccruedInterest}
          isAccruedInterestFixed={true}
          paymentCompleted={true}
          voucher={voucher}
          tokenPrice={tokenPrice}
          useUnits={useUnits}
          cancellationDate={cancellationDate}
        />
      </div>
      {canCancelInvestment && (
        <ActionButtons>
          <Button variant="link" onClick={() => setCancellationModalOpen(true)}>
            <Translate name={'studioInvestmentPaymentDetails.cancelInvestmentButton'} />
          </Button>
        </ActionButtons>
      )}
      {cancellationModalOpen && (
        <InvestmentCancellationModal
          onCancelInvestment={onCancelInvestment}
          loading={loading}
          onClose={() => setCancellationModalOpen(false)}
          type={CancellationModalType.CANCELLATION}
          successCancellation={successCancellation}
          errorCancellation={errorCancellation}
        />
      )}
    </>
  );
};

export default StudioInvestmentPaymentDetails;
