import React, { FunctionComponent } from 'react';
import { AdminIssuerDetail } from 'api/models';
import BoxedContent from 'ui/molecules/boxed-content';
import IssuerInformation from 'apps/issuer/pages/issuer-details/issuer-data-view/issuer-information';
import { UserDataSet } from 'ui/types/user-data-set';
import Translate from 'ui/atoms/translate';
import useIssuerData from 'apps/issuer/pages/issuer-details/issuer-data-view/use-issuer-data';
import useEditIssuerData from 'hooks/use-edit-issuer-data';
import ActionButtons from 'src/ui/molecules/action-buttons';
import Button from 'src/ui/atoms/button';
import IssuerEditModals from './edit-modals';

interface UserDataViewProps {
  issuer: AdminIssuerDetail;
  canEdit: boolean;
  fetchIssuerDetails: () => void;
}

const UserDataView: FunctionComponent<UserDataViewProps> = (props) => {
  const { issuer, canEdit, fetchIssuerDetails } = props;

  const issuerDataProps = useEditIssuerData();

  const issuerSections = useIssuerData({
    ...issuerDataProps,
    issuer,
    canEdit,
  });

  return (
    <>
      {issuerSections.map((issuerDataSet, index) => {
        if (!issuerDataSet) {
          return null;
        }

        return (
          <BoxedContent
            title={<Translate name={`dashboardIssuerIssuerDetails.${issuerDataSet.key}.title`} />}
            releaseSpaceWhenTitleIsEmpty={true}
            key={index}
          >
            <IssuerInformation userDataSet={issuerDataSet as UserDataSet} />
            <ActionButtons verticalAlign>
              {issuerDataSet.editButtons.map(
                (editInfo) =>
                  editInfo.display && (
                    <Button variant="secondary" onClick={() => editInfo.onClick()}>
                      <Translate name={editInfo.text} />
                    </Button>
                  ),
              )}
            </ActionButtons>
          </BoxedContent>
        );
      })}
      <IssuerEditModals issuer={issuer} fetchIssuerDetails={fetchIssuerDetails} {...issuerDataProps} />
    </>
  );
};

export default UserDataView;
